<template>
  <div>
    <vee-field
      v-slot="{ errors }"
      :rules="rules"
    >
      <v-textarea
        v-model="model"
        :label="requredLabel"
        dense
        outlined
        persistent-placeholder
        rows="1"
        :hint="hint"
        :disabled="disabled"
        :error-messages="errors"
        @input="update()"
      />
    </vee-field>
  </div>
</template>
<script>
export default {
  props: {
    title: { type: String, default: null },
    hint: { type: String, default: null },
    fieldId: { type: String, default: 'false' },
    rules: { type: String, default: '' },
    height: { type: String, default: '48px' },
    disabled: { type: Boolean, dafult: false },
    value: {
      type: [String, Number],
      default: null,
    },
  },
  data: () => ({
    model: '',
  }),
  computed: {
    requredLabel() {
      return this.rules.includes('required') ? `${this.title} *` : this.title;
    },
  },
  watch: {
    value(newVal) {
      this.model = newVal;
    },
  },
  created() {
    this.model = this.value;
  },
  methods: {
    update() {
      this.$emit('input', this.model);
      this.$emit('@update');
    },
  },
};
</script>

<style scoped>
::v-deep.v-select .v-chip {
  background-color: white;
  max-width: 100%;
}
::v-deep.v-chip__content {
  background-color: white;
}
::v-deep.v-list {
  background-color: none;
}
</style>
