export const applicationSpecificRoles = {
  EXPERT_DRIVER_DRIVER: 'ExpertDriverPolicy.Driver',
  EXPERT_DRIVER_ADMIN: 'ExpertDriverPolicy.Admin',
  EXPERT_DRIVER_DATA_MANAGER: 'ExpertDriverPolicy.DataManager',

  EXPERT_DATACONNECT_ADMIN: 'ExpertDataconnectPolicy.Admin',
  EXPERT_DATACONNECT_DATA_MANAGER: 'ExpertDataconnectPolicy.DataManager',

  EXPERT_SPEDITION_ADMIN: 'ExpertSpeditionPolicy.Admin',
  EXPERT_SPEDITION_DATA_MANAGER: 'ExpertSpeditionPolicy.DataManager',
};
