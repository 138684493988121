import { claimTypes } from '@/constants/Claims';

export default class DataconnectClaims {
  constructor(claims) {
    let allowAllCompaniesClaim = false;
    if (claims) {
      allowAllCompaniesClaim = claims.some(
        (claim) =>
          claim.type === claimTypes.DATACONNECT_ALLOW_ALL_COMPANIES && claim.value === 'true',
      );
    }
    this.allowAllComapnies = allowAllCompaniesClaim;
    this.originalAllowAllComapnies = allowAllCompaniesClaim;
    this.allowOnlySpecifcComapnies = !allowAllCompaniesClaim;

    this.allowedCompanyIds = [];
    this.originalAllowedCompanyIds = [];
    if (claims) {
      this.allowedCompanyIds = claims
        .filter((x) => x.type === claimTypes.DATACONNECT_COMPANY_ID)
        .map((x) => x.value);
      if (this.allowedCompanyIds.length > 0) {
        this.originalAllowedCompanyIds = [...this.allowedCompanyIds];
      } else {
        this.originalAllowedCompanyIds = [];
      }
    }

    this.isActive = false;
    if (this.allowAllComapnies || this.allowedCompanyIds.length > 0) {
      this.isActive = true;
    }
  }
}
