export default class Organization {
  constructor(organizationData) {
    this.id = organizationData?.id;
    this.code = organizationData ? organizationData.code : '';
    this.name = organizationData ? organizationData.name : '';
    this.nip = organizationData ? organizationData.nip : '';

    this.isActive = organizationData ? organizationData.isActive : false;

    this.services = organizationData ? organizationData.servicePermisions : [];
  }
}
