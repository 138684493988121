import Vue from 'vue';
import Snotify, { SnotifyPosition } from 'vue-snotify';
import 'vue-snotify/styles/material.css';

const options = {
  toast: {
    position: SnotifyPosition.rightBottom,
    timeout: 2000,
    showProgressBar: false,
    closeOnClick: true,
  },
};

Vue.use(Snotify, options);
