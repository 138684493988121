<template>
  <div>logging out</div>
</template>

<script>
import { mapState } from 'vuex';
import { oidcSettings } from '@/oidc';

export default {
  name: 'Logout',
  data: () => ({}),
  computed: {
    ...mapState('oidcStore', ['keycloakClient']),
  },
  async beforeMount() {
    try {
      await this.keycloakClient.logout({
        redirectUri: oidcSettings.post_logout_redirect_uri,
      });
    } catch (err) {
      console.log('logout failed', err);
      this.$router.push('/');
    }
  },
};
</script>

<style scoped lang="scss"></style>
