<template>
  <vee-field
    v-slot="{ errors }"
    :rules="rules"
  >
    <v-container :class="isSelectedService ? 'check' : 'uncheck'">
      <v-row
        align="center"
        class="pa-1"
      >
        <v-col
          cols="7"
          xl="8"
          align="center"
        >
          <v-checkbox
            v-model="isSelectedService"
            :true-value="true"
            :false-value="false"
            class="mt-0"
            color="primary lighten-1"
            :messages="messages"
            :hide-details="!messages"
            :error-messages="errors"
            @click="checked"
          >
            <template v-slot:label>
              <span :id="isSelectedService ? 'checkboxLabel' : ''">{{ label }}</span>
            </template>
          </v-checkbox>
        </v-col>
        <v-col
          v-show="isSelectedService"
          class="configuration-input"
          cols="5"
          xl="4"
        >
          <v-select
            v-model="configuration"
            :items="configurationItems"
            item-text="code"
            item-value="id"
            hide-details=""
            dense
            solo
            flat
            outlined
            color="primary lighten-1"
            :menu-props="{ bottom: true, offsetY: true }"
            @input="update"
          >
            <template v-slot:item="{ item }">
              <v-card
                elevation="0"
                max-width="310px"
                rounded="0"
              >
                <v-card-title>
                  {{ item.label }}
                </v-card-title>
                <v-card-text>
                  <span
                    v-for="(description, index) in item.description"
                    :key="index"
                    class="d-block"
                  >
                    {{ description }}
                  </span>
                  <div v-if="code === 'COD' || code === 'INSURANCE'">
                    <span
                      v-for="(description, index) in item.conditionalDescription"
                      :key="index"
                      class="d-block"
                    >
                      {{ description }}
                    </span>
                  </div>
                </v-card-text>
              </v-card>
            </template>
          </v-select>
        </v-col>
      </v-row>
      <v-row v-if="remark">
        <v-col>
          <span class="font-italic">
            {{ remark }}
          </span>
        </v-col>
      </v-row>
      <slot />
    </v-container>
    <v-row class="my-3">
      <v-divider />
    </v-row>
  </vee-field>
</template>
<script>
import configurationOptions from '@/constants/AdditionalServicesConfigOptions';

export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    label: { type: String, default: null },
    code: { type: String, default: null },
    messages: { type: String, default: null },
    rules: { type: String, default: null },
    id: { type: Number, default: null },
    selectedService: { type: Boolean, default: false },
    activeService: { type: Boolean, default: true },
    visibleService: { type: Boolean, default: true },
    parameters: { type: Boolean, default: false },
    remark: { type: String, default: '' },
  },
  data: () => ({
    configuration: 0,
    configurationItems: [...configurationOptions],
    isSelectedService: false,
  }),
  watch: {
    selectedService(newVal) {
      this.isSelectedService = newVal;
    },
    activeService() {
      this.updateConfigurationSelectedValue();
    },
    visibleService() {
      this.updateConfigurationSelectedValue();
    },
  },
  created() {
    if (this.parameters) {
      this.configurationItems.pop();
    }
    this.isSelectedService = this.selectedService;
  },
  methods: {
    checked() {
      this.$emit('input', this.isSelectedService);
      if (this.isSelectedService) {
        this.configuration = 0;
        this.$emit('@activate', {
          isActive: true,
          code: this.code,
          isVisible: true,
        });
      } else {
        this.$emit('@deactivate');
      }
    },
    update() {
      let isActive;
      let isVisible;
      switch (this.configuration) {
        case 0:
          isActive = true;
          isVisible = true;
          break;
        case 1:
          isActive = false;
          isVisible = true;
          break;
        case 2:
          isActive = true;
          isVisible = false;
          break;
        default:
          isActive = true;
          isVisible = true;
          break;
      }
      this.$emit('@update', {
        isActive,
        code: this.code,
        id: this.id,
        isVisible,
      });
    },
    updateConfigurationSelectedValue() {
      if (this.activeService && this.visibleService) {
        this.configuration = 0;
      } else if (!this.activeService && this.visibleService) {
        this.configuration = 1;
      } else if (this.activeService && !this.visibleService) {
        this.configuration = 2;
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import '@/assets/styles/utilities/variables';
.check {
  background-color: $primary-lighten4 !important;
  color: $primary-lighten1 !important;
  padding: 5px;
}
.uncheck {
  padding: 5px;
}
#checkboxLabel {
  color: $primary-lighten1 !important;
}

::v-deep .configuration-input .v-select__slot {
  min-height: 40px;
}

::v-deep .v-list-item {
  padding: 0px;
}

.v-select-list {
  padding: 0px;
}

.v-card {
  border-bottom: 1px solid $text-lighten2;
  &:hover {
    background-color: $text-lighten2;
  }
}

.v-list-item:last-child .v-card {
  border: none;
}

.v-card__title {
  font-size: 1rem;
  line-height: 1.25rem;
  word-break: keep-all;
  padding: 8px;
}
.v-card__text {
  font-size: 0.625rem;
  line-height: 0.875rem;
  word-break: keep-all;
  padding: 8px;
  padding-top: 0px;
}
</style>
