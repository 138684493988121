import { claimTypes } from '@/constants/Claims';

export default class DriverClaims {
  constructor(claims) {
    this.driverId = null;
    this.orignalDriverId = null;
    if (claims) {
      const driverIdClaim = claims.find((x) => x.type === claimTypes.DRIVER_ID);
      if (driverIdClaim) {
        this.driverId = driverIdClaim.value;
        this.orignalDriverId = driverIdClaim.value;
      }
    }

    this.isActive = false;
    if (this.driverId) {
      this.isActive = true;
    }
  }
}
