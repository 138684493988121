import { SET_DOCUMENT_NUMBER } from '@/store/mutation-types';

export default {
  namespaced: true,
  state: {
    documentNumber: '',
  },
  mutations: {
    [SET_DOCUMENT_NUMBER](state, payload) {
      state.documentNumber = payload;
    },
  },
  actions: {},
  getters: {},
};
