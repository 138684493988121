import { applicationSpecificRoles } from '@/constants/ApplicationSpecificRoles';

export default class ApplicationSpecificRoles {
  constructor(appRoles) {
    this.isDriver = false;
    this.isDriverAdmin = false;
    this.isDriverDataManager = false;

    this.isDataconnectAdmin = false;
    this.isDataconnectDataManager = false;

    this.isSpeditionAdmin = false;
    this.isSpeditionDataManager = false;

    if (appRoles) {
      // driver
      if (appRoles.some((x) => x === applicationSpecificRoles.EXPERT_DRIVER_DRIVER)) {
        this.isDriver = true;
      }

      if (appRoles.some((x) => x === applicationSpecificRoles.EXPERT_DRIVER_ADMIN)) {
        this.isDriverAdmin = true;
      }

      if (appRoles.some((x) => x === applicationSpecificRoles.EXPERT_DRIVER_DATA_MANAGER)) {
        this.isDriverDataManager = true;
      }

      // dataconnect
      if (appRoles.some((x) => x === applicationSpecificRoles.EXPERT_DATACONNECT_ADMIN)) {
        this.isDataconnectAdmin = true;
      }

      if (appRoles.some((x) => x === applicationSpecificRoles.EXPERT_DATACONNECT_DATA_MANAGER)) {
        this.isDataconnectDataManager = true;
      }

      // spedition
      if (appRoles.some((x) => x === applicationSpecificRoles.EXPERT_SPEDITION_ADMIN)) {
        this.isSpeditionAdmin = true;
      }

      if (appRoles.some((x) => x === applicationSpecificRoles.EXPERT_SPEDITION_DATA_MANAGER)) {
        this.isSpeditionDataManager = true;
      }
    }
  }
}
