<template>
  <div>
    <h1>{{ $t('accessDenied') }}</h1>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { oidcSettings } from '@/oidc';

export default {
  name: 'Logout',
  data: () => ({}),
  computed: {
    ...mapState('oidcStore', ['keycloakClient']),
  },
  created() {
    setTimeout(this.logout, 2000);
  },
  methods: {
    async logout() {
      try {
        await this.keycloakClient.logout({
          redirectUri: oidcSettings.post_logout_redirect_uri,
        });
      } catch (err) {
        console.log('logout failed', err);
        this.$router.push('/');
      }
    },
  },
};
</script>
<style scoped lang="scss"></style>
