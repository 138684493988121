<template>
  <v-app id="inspire">
    <div
      v-if="!onLine"
      class="offline"
    >
      BRAK POŁĄCZENIA Z INTERNETEM
    </div>
    <transition
      name="fade"
      mode="out-in"
      appear
    >
      <router-view />
    </transition>
    <vue-snotify />

    <v-dialog
      v-model="termsAndConditionsDialog.visible"
      width="630px"
      persistent
    >
      <TermsAndConditions
        :are-terms-and-conditions-accepted="termsAndConditionsDialog.areTermsAndConditionsAccepted"
        :are-price-terms-accepted="termsAndConditionsDialog.arePriceTermsAccepted"
        @@close="termsAndConditionsDialog.visible = false"
      />
    </v-dialog>
  </v-app>
</template>

<script>
import policyServerApiService from '@/services/policyServer/policy-server-api';
import { mapGetters } from 'vuex';
import TermsAndConditions from '@/components/dialogs/TermsAndConditions.vue';

export default {
  name: '',
  components: {
    TermsAndConditions,
  },
  data: () => ({
    onLine: navigator.onLine,
    termsAndConditionsDialog: {
      visible: false,
      areTermsAndConditionsAccepted: false,
      arePriceTermsAccepted: false,
    },
  }),

  computed: {
    ...mapGetters('oidcStore', ['oidcUser']),

    getUserOrganizationId() {
      return this.oidcUser.tenantId;
    },
  },

  async mounted() {
    window.addEventListener('online', this.updateOnlineStatus);
    window.addEventListener('offline', this.updateOnlineStatus);
    const data = await this.getUserOrganization();
    if (!data) return;
    this.setTermsAndConditionsDialogValues(
      data?.termsAndConditionsAcceptedAt,
      data?.priceTermsAcceptedAt,
    );
  },

  beforeDestroy() {
    window.removeEventListener('online', this.updateOnlineStatus);
    window.removeEventListener('offline', this.updateOnlineStatus);
  },

  methods: {
    updateOnlineStatus() {
      this.onLine = navigator.onLine;
      if (navigator.onLine) {
        this.$snotify.success('Przywrócono połączenie z internetem!');
        return;
      }
      this.$snotify.error('Straciłeś połączenie z internetem!');
    },

    // eslint-disable-next-line consistent-return
    async getUserOrganization() {
      try {
        const { data } = await policyServerApiService.getOrganizationById(
          this.getUserOrganizationId,
        );
        return data;
      } catch (err) {
        console.error(err);
      }
    },

    setTermsAndConditionsDialogValues(termsAndConditionsAcceptedAt, priceTermsAcceptedAt) {
      this.termsAndConditionsDialog.arePriceTermsAccepted = !!priceTermsAcceptedAt;
      this.termsAndConditionsDialog.areTermsAndConditionsAccepted = !!termsAndConditionsAcceptedAt;
      this.termsAndConditionsDialog.visible =
        !termsAndConditionsAcceptedAt || !priceTermsAcceptedAt;
    },
  },
};
</script>

<style lang="scss">
.offline {
  position: fixed;
  z-index: 1000;
  width: 100%;
  top: 0;
  background: #e53935;
  text-align: center;
  color: #ffffff;
  font-size: 14px;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.1s;
}
.fade-enter,
.fade-leave-active {
  opacity: 0;
}
</style>
