import Vue from 'vue';
import i18n from '@/plugins/i18n';
import { ValidationProvider, ValidationObserver, extend, localize } from 'vee-validate';
import { numeric, required, double, min, max } from 'vee-validate/dist/rules';

extend('required', {
  ...required,
  message: i18n.t('setValidation.requiredField').toString(),
});

extend('url', {
  validate(value) {
    if (value) {
      return /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)$/.test(
        value,
      );
    }
    return false;
  },
  message: i18n.t('setValidation.validUrl').toString(),
});

extend('not_custom_shipment', {
  validate(value) {
    if (value && value.toLowerCase() !== 'inny sposób wysyłki') {
      return true;
    }
    return false;
  },
  message: i18n.t('setValidation.is_not').toString(),
});

extend('double', {
  ...double,
  message: i18n.t('setValidation.numericField').toString(),
});

extend('numeric', {
  ...numeric,
  message: i18n.t('setValidation.numericField').toString(),
});

extend('min', {
  ...min,
  message: i18n.t('setValidation.min').toString(),
});

extend('max', {
  ...max,
  message: i18n.t('setValidation.max').toString(),
});

extend('iban', {
  validate(value) {
    if (value) {
      return /^[A-Z]{2}(?:[ ]?[0-9]){18,35}$/.test(value);
    }
    return false;
  },
  message: i18n.t('setValidation.iban').toString(),
});

extend('greaterThanZero', {
  validate(value) {
    return value > 0;
  },
  message: i18n.t('setValidation.greaterThanZero').toString(),
});
// set default language
localize(process.env.VUE_APP_I18N_LOCALE || 'en');

Vue.component('VeeForm', ValidationObserver);
Vue.component('VeeField', ValidationProvider);
