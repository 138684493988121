<template>
  <v-card class="pa-3">
    <v-card-title class="d-flex justify-center">
      <span class="text-h4 text--primary-lighten1 font-weight-bold">
        {{ $t('termsAndConditions.title') }}!
      </span>
    </v-card-title>

    <v-divider class="ma-3" />

    <v-card-text class="py-0">
      <span class="text-subtitle-1 text--text">
        {{ $t('termsAndConditions.content.requiredAcceptace') }}
      </span>
      <span>
        <a
          class="text-subtitle-1 text--primary-lighten1 font-weight-bold"
          href="https://nubowms.pl/nubowms-regulamin-2/"
          target="_blank"
        >
          {{ $t('termsAndConditions.content.nuboTerms') }}</a
        >,
        <a
          class="text-subtitle-1 text--primary-lighten1 font-weight-bold"
          href="https://nubowms.pl/cennik/"
          target="_blank"
        >
          {{ $t('termsAndConditions.content.priceTerms') }}</a
        >
      </span>
      <span class="text-subtitle-1 text--text">
        {{ $t('termsAndConditions.content.businessTerms') }}
      </span>
    </v-card-text>

    <v-divider class="ma-3" />

    <div
      class="ma-3 pa-1"
      :class="{ active: termsAndConditionsAccepted }"
    >
      <v-checkbox
        v-model="termsAndConditionsAccepted"
        :disabled="areTermsAndConditionsAccepted"
        color="primary lighten-1"
        class="mt-0 pt-0"
        hide-details
      >
        <template #label>
          <span class="text-subtitle-1 text--text">
            {{ $t('termsAndConditions.acceptTermsAndConditions') }}
          </span>
        </template>
      </v-checkbox>
    </div>

    <div
      class="ma-3 pa-1"
      :class="{ active: priceTermsAccepted }"
    >
      <v-checkbox
        v-model="priceTermsAccepted"
        :disabled="arePriceTermsAccepted"
        color="primary lighten-1"
        class="mt-0 pt-0"
        hide-details
      >
        <template #label>
          <span class="text-subtitle-1 text--text">
            {{ $t('termsAndConditions.priceTerms') }}
          </span>
        </template>
      </v-checkbox>
    </div>

    <v-divider class="ma-3" />

    <v-card-actions>
      <v-btn
        color="primary lighten-1"
        elevation="0"
        width="48%"
        outlined
        @click="onLogout()"
      >
        {{ $t('actions.logout') }}
      </v-btn>
      <v-btn
        :disabled="!termsAndConditionsAccepted || !priceTermsAccepted"
        color="primary lighten-1"
        elevation="0"
        width="48%"
        @click="onAccept()"
      >
        {{ $t('actions.start') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import policyServerApiService from '@/services/policyServer/policy-server-api';

export default {
  name: 'TermsAndConditions',

  props: {
    areTermsAndConditionsAccepted: {
      type: Boolean,
      required: true,
    },
    arePriceTermsAccepted: {
      type: Boolean,
      required: true,
    },
  },

  data: () => ({
    termsAndConditionsAccepted: false,
    priceTermsAccepted: false,
  }),

  mounted() {
    this.termsAndConditionsAccepted = this.areTermsAndConditionsAccepted;
    this.priceTermsAccepted = this.arePriceTermsAccepted;
  },

  methods: {
    onLogout() {
      this.$router.push({ name: 'oidcLogout' });
    },
    async onAccept() {
      const urlParams = new URLSearchParams({
        isAcceptedTermsAndConditions: this.termsAndConditionsAccepted,
        isAcceptedPriceTerms: this.priceTermsAccepted,
      });
      try {
        await policyServerApiService.setAcceptTermsAndConditions(urlParams);
        this.$emit('@close');
      } catch (err) {
        console.error(err);
      }
    },
  },
};
</script>

<style lang="scss">
.active {
  background-color: $primary-lighten4;
  border-radius: 4px;
}
</style>
