const ALLOWED_COURIER_CLAIM_TYPE = 'ExpertSpedition.AllowedCourierCode';

const DRIVER_ID_CLAIM_TYPE = 'ExpertDriver.DriverId';

const DATACONNECT_COMPANY_ID_CLAIM_TYPE = 'ExpertDataconnect.CompanyId';
const DATACONNECT_ALLOW_ALL_COMPANIES_CLAIM_TYPE = 'ExpertDataconnect.AllowAllCompanies';

const ORGANIZATION_ID_CLAIM_TYPE = 'DC.Organization.Id';

const EXPERT_DRIVER_ENABLED_CLAIM_TYPE = 'DC.ExpertDriver.Enabled';
const DATACONNECT_ENABLED_CLAIM_TYPE = 'DC.DataConnect.API.Enabled';

const ROLE_CLAIM_TYPE = 'role';

export const claimTypes = {
  ALLOWED_COURIER: ALLOWED_COURIER_CLAIM_TYPE,
  DRIVER_ID: DRIVER_ID_CLAIM_TYPE,
  DATACONNECT_COMPANY_ID: DATACONNECT_COMPANY_ID_CLAIM_TYPE,
  DATACONNECT_ALLOW_ALL_COMPANIES: DATACONNECT_ALLOW_ALL_COMPANIES_CLAIM_TYPE,
  ORGANIZATION_ID: ORGANIZATION_ID_CLAIM_TYPE,
  EXPERT_DRIVER_ENABLED: EXPERT_DRIVER_ENABLED_CLAIM_TYPE,
  DATACONNECT_ENABLED: DATACONNECT_ENABLED_CLAIM_TYPE,
  ROLE: ROLE_CLAIM_TYPE,
};
