import SpeditionClaims from '@/interfaces/authorizationClaims/SpeditionClaims';
import DriverClaims from '@/interfaces/authorizationClaims/DriverClaims';
import DataconnectClaims from '@/interfaces/authorizationClaims/DataconnectClaims';
import ApplicationSpecificRoles from '@/interfaces/ApplicationSpecificRoles';

export default class User {
  constructor(userData) {
    // if (userData && userData.id > 0) {
    //   this.id = userData.id;
    // }
    this.id = userData ? userData.id : '';

    this.subjectId = userData ? userData.subjectId : '';
    this.identityRole = userData ? userData.identityRole : '';
    this.email = userData ? userData.email : '';
    this.userName = userData ? userData.username : '';
    this.firstName = userData ? userData.firstName : '';
    this.lastName = userData ? userData.lastName : '';
    this.fullName = userData ? userData.fullName : '';
    this.organizationName = userData ? userData.organizationName : '';
    this.organizationId = userData ? userData.organizationId : '';
    this.isActive = userData ? userData.isActive : '';

    this.organizationNips = [];
    this.applicationRoles = userData ? userData.applicationRoles : [];
    this.lockoutEnd = userData ? userData.lockoutEnd : '';

    if (userData) {
      this.speditionClaims = new SpeditionClaims(userData.authroizationClaims);
      this.driverClaims = new DriverClaims(userData.authroizationClaims);
      this.dataconnectClaims = new DataconnectClaims(userData.authroizationClaims);

      this.appRoles = new ApplicationSpecificRoles(userData.applicationRoles);
    } else {
      this.speditionClaims = new SpeditionClaims(null);
      this.driverClaims = new DriverClaims(null);
      this.dataconnectClaims = new DataconnectClaims(null);

      this.appRoles = new ApplicationSpecificRoles(null);
    }

    // other stuff for IS4
    this.emailConfirmed = false;
    this.lockoutEnabled = true;
    this.phoneNumber = null;
    this.phoneNumberConfirmed = false;
    this.twoFactorEnabled = false;
  }
}
